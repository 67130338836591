import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const isProd = () =>
  process.env.NODE_ENV === 'production' &&
  utils.host.getHostname() !== 'localhost'

export default function errorTracking() {
  if (isProd()) {
    Sentry.init({
      dsn: 'https://ef1a7ec34d794297a7cbde6b383cb379@sentry.io/1402208',
      environment: utils.host.getHostname(),
      ignoreErrors: ['lastpass', 'ResizeObserver'],
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate:
        utils.host.isInDevMode || utils.host.isRunningOnDev ? 1.0 : 0.25,
    })
  }
}

export const logComponentDidCatch = (error: any, errorInfo: any) => {
  if (isProd()) {
    Sentry.withScope((scope) => {
      R.forEachObjIndexed(
        (value, key) => scope.setExtra(key as string, value),
        errorInfo,
      )
      Sentry.captureException(error)
    })
  }
}
