import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'

//import initializeErrorTracking from 'lib/errorTracking'
import { setupEventTracking } from 'lib/eventTracking'
import * as Rollbar from '@rollbar/react'
import 'lib/styles/css/print.css'
import 'lib/styles/css/modals.css'
import 'domains/commonCartridge/styles/ccReport.css'
import 'lib/styles/css/index.css'
import store from 'lib/store'
import theme from 'lib/styles/theme'
import StartupScreen from 'domains/application/screens/StartupScreen'
import { hideFreshDeskWidget } from 'lib/freshdesk'
import { utils } from '@ims/1edtech-frontend-common'

const rollbarConfig = {
  accessToken: utils.env.getEnvVariable(
    'ROLLBAR_ACCESS_TOKEN',
    process.env.ROLLBAR_ACCESS_TOKEN,
  ),
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: utils.host.getHostname(),
  disabled: utils.host.getHostname() === 'localhost',
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
}
//initializeErrorTracking()
setupEventTracking()
hideFreshDeskWidget()
ReactModal.setAppElement('#modals')
ReactDOM.render(
  <Rollbar.Provider config={rollbarConfig}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StartupScreen />
      </ThemeProvider>
    </Provider>
  </Rollbar.Provider>,
  document.getElementById('root'),
)
