import React from 'react'
import memoize from 'fast-memoize'
import { useSelector } from 'react-redux'

import './NavigationDrawer.css'
import { Icon, Text, View } from 'lib/components'
import { withScreenSize, IScreenSizeProps } from 'lib/utils/screenSize'
import DrawerLink, {
  IBaseDrawerLinkProps,
} from 'domains/application/components/DrawerLink'
import * as routes from 'domains/application/navigation/routes'
import { APPLICATION_NAME } from 'domains/application/constants/application'
import {
  isSupplierRoleSelector,
  isIMSAdminRoleSelector,
} from 'domains/authentication/selectors/roles'
import Header from 'domains/application/components/Header'
import Footer from 'domains/application/components/Footer'
import { RootState } from 'lib/store/rootReducer'
import JoinIMSDrawerLink from 'domains/application/components/JoinIMSDrawerLink'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import CreateAdminNotificationModal from 'domains/notifications/modals/CreateAdminNotificationModal'
import { utils } from '@ims/1edtech-frontend-common'
import { NON_MEMBER_ORG_MEMBERSHIP_DISPLAY } from 'domains/orgs/constants/organization'
import { ONEROSTER_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'
import * as roles from 'domains/users/constants/userRoles'
import IUser, { userHasRole } from 'domains/users/models/IUser'
import { meSelector } from 'domains/authentication/selectors/me'
import SupportModal from 'domains/support/components/SupportModal'
import { goToHelp } from 'domains/application/workflows/goToHelp'

const supplierLinks: IBaseDrawerLinkProps[] = [
  {
    title: 'Home',
    icon: 'fas fa-home',
    route: routes.SUPPLIER_DASHBOARD_ROUTE,
    requiredRoles: [],
  },
  {
    title: 'OneRoster Check',
    icon: '/images/ORIcon.svg',
    isImg: true,
    baseRoute: ONEROSTER_BASE_NAV_ROUTE,
    requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
    subLinks: [
      {
        title: 'Dashboard',
        icon: 'fas fa-tachometer-alt',
        route: routes.SUPPLIER_OR_DASHBOARD_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
      {
        title: 'Product Catalog',
        icon: 'fas fa-book',
        route: routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
      {
        title: 'Characterizations',
        icon: 'fingerprint',
        isMaterial: true,
        route: `${routes.SUPPLIER_OR_CHARACTERIZATIONS_ROUTE}`,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
      {
        title: 'Comparisons',
        icon: 'fas fa-poll',
        route: routes.SUPPLIER_OR_COMPARISONS_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
    ],
  },
  {
    title: 'Common Cartridge Check',
    icon: '/images/CCIcon.svg',
    isImg: true,
    route: `${routes.SUPPLIER_CC_DASHBOARD_ROUTE}`,
    requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
  },
  {
    title: 'Users',
    icon: 'fas fa-user-tie',
    route: routes.SUPPLIER_USERS_ROUTE,
    requiredRoles: [roles.ADMIN_ROLE],
  },
]

const districtLinks: IBaseDrawerLinkProps[] = [
  {
    title: 'Home',
    icon: 'fas fa-home',
    route: routes.DISTRICT_DASHBOARD_ROUTE,
    requiredRoles: [],
  },
  {
    title: 'OneRoster Check',
    icon: '/images/ORIcon.svg',
    isImg: true,
    baseRoute: ONEROSTER_BASE_NAV_ROUTE,
    requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
    subLinks: [
      {
        title: 'Dashboard',
        icon: 'fas fa-tachometer-alt',
        route: routes.DISTRICT_OR_DASHBOARD_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
      {
        title: 'Product Catalog',
        icon: 'fas fa-book',
        route: routes.DISTRICT_OR_PRODUCT_CATALOG_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
      {
        title: 'Characterizations',
        icon: 'fingerprint',
        isMaterial: true,
        route: `${routes.DISTRICT_OR_CHARACTERIZATIONS_ROUTE}`,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
      {
        title: 'Comparisons',
        icon: 'fas fa-poll',
        route: routes.DISTRICT_OR_COMPARISONS_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
      },
    ],
  },
  {
    title: 'TrustEd Apps',
    icon: 'fas fa-clipboard-list',
    baseRoute: routes.DISTRICT_TRUSTED_APPS_ROUTE,
    requiredRoles: [
      roles.ADMIN_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
      roles.CCX_USER_ROLE,
    ],
    subLinks: [
      {
        title: 'My Dashboard',
        icon: 'fas fa-tachometer-alt',
        route: routes.DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE,
        requiredRoles: [
          roles.ADMIN_ROLE,
          roles.TA_ADMIN_ROLE,
          roles.TA_CONTRIBUTOR_ROLE,
          roles.CCX_USER_ROLE,
        ],
      },
      {
        title: 'Setup',
        icon: 'fas fa-cog',
        route: routes.DISTRICT_TRUSTED_APPS_SETUP_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE],
      },
      {
        title: 'Shared Dashboards',
        icon: 'fab fa-slideshare',
        route: routes.DISTRICT_TRUSTED_APPS_SHARED_DASHBOARDS_ROUTE,
        requiredRoles: [roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE],
      },
      {
        title: 'Changelog',
        icon: 'fas fa-list',
        route: routes.DISTRICT_TRUSTED_APPS_CHANGELOG_ROUTE,
        requiredRoles: [
          roles.ADMIN_ROLE,
          roles.TA_ADMIN_ROLE,
          roles.TA_CONTRIBUTOR_ROLE,
          roles.CCX_USER_ROLE,
        ],
      },
    ],
  },
  {
    title: 'Common Cartridge Check',
    icon: '/images/CCIcon.svg',
    isImg: true,
    route: `${routes.DISTRICT_CC_DASHBOARD_ROUTE}`,
    requiredRoles: [roles.ADMIN_ROLE, roles.CCX_USER_ROLE],
  },
  /* TODO: Reinstate on micrometer release {
    title: 'Certify a Product',
    icon: 'fas fa-clipboard-check',
    route: 'https://micrometer-dev.1edtech.cloud/',
    external: true,
    requiredRoles: [roles.ADMIN_ROLE],
    }, */
  {
    title: 'Users',
    icon: 'fas fa-user-tie',
    route: routes.DISTRICT_USERS_ROUTE,
    requiredRoles: [roles.ADMIN_ROLE],
  },
]
const imsLinks: IBaseDrawerLinkProps[] = [
  {
    title: 'Dashboard Home',
    icon: 'fas fa-home',
    route: routes.ADMIN_DASHBOARD_ROUTE,
    requiredRoles: [],
  },
  {
    title: 'Organizations',
    icon: 'fas fa-sitemap',
    route: routes.ADMIN_ORGANIZATIONS_ROUTE,
    requiredRoles: [],
  },
  {
    title: 'Configurations',
    icon: 'fas fa-cog',
    baseRoute: routes.ADMIN_CONFIGURATIONS_ROOT_ROUTE,
    requiredRoles: [],
    subLinks: [
      {
        title: 'Sources',
        icon: 'fas fa-list',
        route: routes.ADMIN_SOURCES_ROUTE,
        requiredRoles: [],
      },
      {
        title: 'LTI Platforms',
        icon: 'fas fa-project-diagram',
        route: routes.ADMIN_LTI_PLATFORMS_ROUTE,
        requiredRoles: [],
      },
      {
        title: 'Event Header',
        icon: 'fas fa-link',
        route: routes.ADMIN_LANDING_PAGE_URL_ROUTE,
        requiredRoles: [],
      },
    ],
  },
]

interface IProps {
  children: any
}

function Drawer(props: IProps & IScreenSizeProps) {
  const [open, setOpen] = React.useState(!props.smallerThanMedium)
  const [createNotificationOpen, setCreateNotificationOpen] =
    React.useState(false)

  const [isSupportModalOpen, setIsSupportModalOpen] = React.useState(false)

  const drawer = React.useRef<HTMLDivElement>(null)

  const me = useSelector(meSelector) as IUser | null
  const isSupplier = useSelector(isSupplierRoleSelector)
  const isIMSAdmin = useSelector(isIMSAdminRoleSelector)
  const preferrers = useSelector((s: RootState) =>
    myOrganizationPropSelector('preferrers', [])(s, props),
  )
  const membershipLevel = useSelector((s: RootState) =>
    myOrganizationPropSelector(
      'membershipLevel',
      NON_MEMBER_ORG_MEMBERSHIP_DISPLAY,
    )(s, props),
  )

  const handleClick = React.useCallback(
    (e) => {
      if (drawer.current && open && props.hasSmallScreen) {
        if (drawer.current.contains(e.target)) {
          return
        }
        setOpen(false)
      }
    },
    [open, props.hasSmallScreen],
  )

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => {
      document.removeEventListener('mousedown', handleClick, false)
    }
  }, [open, handleClick])

  React.useEffect(() => {
    if (props.smallerThanMedium) {
      closeDrawer()
    } else {
      openDrawer()
    }
  }, [props.smallerThanMedium])

  const getDrawerStateClassName = memoize((isOpen, hasSmallScreen) => {
    if (isOpen) {
      return hasSmallScreen ? 'over' : 'open'
    }
    return hasSmallScreen ? 'hide' : 'closed'
  })

  const getCollapsibleClassName = memoize(
    (isOpen) => `collapsible ${isOpen ? 'open' : 'closed'}`,
  )

  const openDrawer = () => setOpen(true)

  const closeDrawer = () => setOpen(false)

  const toggleDrawer = () => setOpen(!open)

  const openCreateNotification = () => setCreateNotificationOpen(true)

  const closeCreateNotification = () => setCreateNotificationOpen(false)

  const canViewLink = (link: IBaseDrawerLinkProps) => {
    const canViewLink = userHasRole(me, link.requiredRoles)

    return canViewLink
  }

  const renderLink = (linkProps: IBaseDrawerLinkProps) => {
    const onClick = props.hasSmallScreen
      ? () => {
          if (linkProps.onClick) {
            linkProps.onClick()
          }
          closeDrawer()
        }
      : linkProps.onClick

    return (
      <DrawerLink
        key={`${linkProps.title}-${linkProps.route}`}
        {...linkProps}
        onClick={onClick}
        open={open}
      />
    )
  }

  const className = getDrawerStateClassName(open, props.hasSmallScreen)

  const allIMSLinks: IBaseDrawerLinkProps[] = [
    ...imsLinks,
    {
      title: 'New Notification',
      icon: 'fas fa-bell',
      onClick: openCreateNotification,
      manual: true,
      route: 'NewNotification',
      requiredRoles: [roles.ADMIN_ROLE],
    },
    {
      title: 'Users',
      icon: 'fas fa-user-tie',
      route: routes.ADMIN_USERS_ROUTE,
      requiredRoles: [roles.ADMIN_ROLE],
    },
  ]

  let links: IBaseDrawerLinkProps[]
  if (isIMSAdmin) links = allIMSLinks
  else if (isSupplier) links = supplierLinks
  else links = districtLinks

  links = links
    .map((link) => {
      const canView = canViewLink(link)
      if (!canView) return null
      if (link.subLinks)
        return {
          ...link,
          subLinks: link.subLinks.filter((subLink) => canViewLink(subLink)),
        }

      return link
    })
    .filter((l) => l !== null) as IBaseDrawerLinkProps[]

  const hideHelp =
    utils.hasValue(preferrers) &&
    membershipLevel === NON_MEMBER_ORG_MEMBERSHIP_DISPLAY

  return (
    <nav id="drawer-wrapper" className={className}>
      <View
        id="drawer"
        className={className}
        bg="primary"
        position="fixed"
        top={0}
        left={0}
        height="100%"
        flexible="column"
        ref={drawer}
      >
        <View
          flexible="row-v-center"
          position="absolute"
          top={14}
          left={16}
          className={getCollapsibleClassName(open)}
        >
          <Text fontSize={20} fontWeight={700} color="#fff" lineHeight="20px">
            {APPLICATION_NAME}
          </Text>
          <View flex={1} />
        </View>

        <Icon
          id="drawer-hamburger"
          position="absolute"
          cursor="pointer"
          top={0}
          right={0}
          p={16}
          pt={22}
          onClick={toggleDrawer}
          className="fas fa-bars"
          color="white"
          fontSize={20}
        />
        <View pt={64}>
          {links.map(renderLink)}
          {!hideHelp && (
            <DrawerLink
              title={'FAQ'}
              icon={'fas fa-book-open'}
              route=""
              open={open}
              onClick={goToHelp}
              manual={true}
              requiredRoles={[]}
            />
          )}
          <DrawerLink
            title={'Support'}
            icon={'fas fa-question-circle'}
            route="mailto:support@1edtech.org"
            open={open}
            requiredRoles={[]}
            external
          />
        </View>

        <View flex={1} />
        <JoinIMSDrawerLink open={open} />
      </View>
      <View id="main" className={className}>
        <Header
          toggleDrawer={props.hasSmallScreen ? toggleDrawer : undefined}
        />
        {props.children}
        <View pl={3}>
          <Footer />
        </View>
      </View>

      <CreateAdminNotificationModal
        isOpen={createNotificationOpen}
        closeModal={closeCreateNotification}
      />
      <SupportModal
        isOpen={isSupportModalOpen}
        onClose={() => {
          setIsSupportModalOpen(false)
        }}
      />
    </nav>
  )
}

export default withScreenSize(Drawer) as (props: IProps) => any
