import React from 'react'

import { View, Text, Image, Button } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import * as routes from 'domains/application/navigation/routes'
import {
  isDistrictRoleSelector,
  isSupplierRoleSelector,
} from 'domains/authentication/selectors/roles'
import { useSelector } from 'react-redux'
import { meSelector } from 'domains/authentication/selectors/me'
import { userHasRole } from 'domains/users/models/IUser'
import * as roles from 'domains/users/constants/userRoles'
import { Link } from 'react-router-dom'
import { goToHelp } from 'domains/application/workflows/goToHelp'
import { applicationPropSelector } from 'domains/application/selectors/application'
import {
    EVENT_BUTTON_TEXT,
  EVENT_HEADER_IMAGE_URL,
  EVENT_HEADER_LINK_URL,
} from 'domains/application/models/configuration'

export default function ApplicationDashboardScreen() {
  const me = useSelector(meSelector)
  const isSupplier = useSelector(isSupplierRoleSelector)
  const isDistrict = useSelector(isDistrictRoleSelector)
  const linkUrl: string = useSelector(
    applicationPropSelector(EVENT_HEADER_LINK_URL),
  )
  const imageUrl: string = useSelector(
    applicationPropSelector(EVENT_HEADER_IMAGE_URL),
  )
  const buttonText: string = useSelector(
    applicationPropSelector(EVENT_BUTTON_TEXT),
  )

  return (
    <div>
      <View
        variant="screen"
        className="h-96 w-full bg-background mb-4 flex justify-between"
      >
        <div className="flex items-center h-full">
          <Image src={imageUrl} className="h-48" />
        </div>
        <a
          className="h-full flex items-end py-8"
          href={linkUrl}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="secondary" large>
            {buttonText || 'Learn More'}
          </Button>
        </a>
      </View>
      <View variant="screen" className="my-4">
        <DocumentTitle title="Home" />
        <Text fontSize="jumbo" className="font-bold mx-32 my-8">
          Quick Links
        </Text>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 transition-all duration-200 m-8 xl:mx-16 place-items-center">
          {isDistrict &&
            userHasRole(me, [
              roles.ADMIN_ROLE,
              roles.CCX_USER_ROLE,
              roles.TA_ADMIN_ROLE,
              roles.TA_CONTRIBUTOR_ROLE,
            ]) && (
              <Link
                className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
                to={routes.DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE}
                rel="noreferrer"
              >
                <Image
                  src="/images/TrustEdAppsDashboard.svg"
                  className="h-32 mb-4"
                />
                <Text fontSize="large" className="font-bold">
                  TrustEd Apps Dashboard
                </Text>
              </Link>
            )}

          {userHasRole(me, [roles.ADMIN_ROLE, roles.CCX_USER_ROLE]) && (
            <Link
              className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
              to={
                isSupplier
                  ? routes.SUPPLIER_OR_DASHBOARD_ROUTE
                  : routes.DISTRICT_OR_DASHBOARD_ROUTE
              }
              rel="noreferrer"
            >
              <Image src="/images/ORCheck.svg" className="h-32 mb-4" />
              <Text fontSize="large" className="font-bold">
                OneRoster Check
              </Text>
            </Link>
          )}

          {userHasRole(me, [roles.ADMIN_ROLE, roles.CCX_USER_ROLE]) && (
            <Link
              className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
              to={
                isDistrict
                  ? routes.DISTRICT_CC_DASHBOARD_ROUTE
                  : routes.SUPPLIER_CC_DASHBOARD_ROUTE
              }
              rel="noreferrer"
            >
              <Image src="/images/CCCheck.svg" className="h-32 mb-4" />
              <Text fontSize="large" className="font-bold">
                Common Cartridge Check
              </Text>
            </Link>
          )}

          <a
            className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
            href="https://learn.1edtech.org"
            target="_blank"
            rel="noreferrer"
          >
            <Image src="/images/1EdTech Learn.svg" className="h-32 mb-4" />
            <Text fontSize="large" className="font-bold">
              1EdTech Learn
            </Text>
          </a>

          <a
            className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
            href="http://community.1edtech.org/"
            target="_blank"
            rel="noreferrer"
          >
            <Image src="/images/Community.svg" className="h-32 mb-4" />
            <Text fontSize="large" className="font-bold">
              Community
            </Text>
          </a>

          <a
            className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
            href="https://site.imsglobal.org/certifications"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src="/images/TrustEdAppsDirectory.svg"
              className="h-32 mb-4"
            />
            <Text fontSize="large" className="font-bold">
              TrustEd Apps Directory
            </Text>
          </a>

          <a
            className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
            href="https://casenetwork.1edtech.org/"
            target="_blank"
            rel="noreferrer"
          >
            <Image src="/images/CASENetwork.svg" className="h-32 mb-4" />
            <Text fontSize="large" className="font-bold">
              CASE Network
            </Text>
          </a>
          <button
            className="bg-gray-50 rounded-md drop-shadow-lg h-64 w-64 flex flex-col items-center justify-center p-4 hover:bg-gray-100 cursor-pointer"
            onClick={() => {
              goToHelp()
            }}
          >
            <Image src="/images/Support.svg" className="h-32 mb-4" />
            <Text fontSize="large" className="font-bold">
              Support
            </Text>
          </button>
        </div>
      </View>
    </div>
  )
}
